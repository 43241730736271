import Vue from 'vue';
import App from './App.vue';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import Routes from './routes';
import * as VueGoogleMaps from 'gmap-vue';
import VueGtag from "vue-gtag";
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';
import VueLazyload from 'vue-lazyload'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});
Vue.use(VueMoment, {
  moment,
});
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAC31MhYk5S_oPDnjw9XNYskk9WXHdW1Gs',
    libraries: 'places',
  },
  installComponents: true
});
Vue.use(VueGtag, {
  config: { id: "G-8EE2JSYYSN",
    params: {
      send_page_view: false
    }
  }
});
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: './assets/loader.gif',
  loading: './assets/loader.gif',
});

const router = new VueRouter({
  mode: 'history',
  routes: Routes
});

new Vue({
  render: (h) => h(App),
  router:router,
}).$mount('#app')