<template>
  <div id="products">

    <div class="row contenedor">
      <br>
      <div class="v-alert" id="div_empty_cart" v-if="products_not_found && !isLoading">
        <div class="col s12 m12">
          <i class="inline-icon material-icons">info</i>
          No encontramos productos que coincidan con la búsqueda.
        </div>
      </div>
      <div class="v-alert" id="div_empty_cart"
      v-if="(availableInBranch == 'false' || available == 'false') && !isLoading">
        <div class="col s12 m12">
          <i class="inline-icon material-icons">info</i>
          Producto no disponible<span v-if="availableInBranch == 'false'"> en sucursal seleccionada</span>.
        </div>
      </div>
      <div class="col s12 left-align" v-if="!isLoading">
        <div v-if="categoryId" class="chip">
          <b>Categoría: </b>{{category}}
          <i @click="removeCategory" class="close material-icons">close</i>
        </div>
        <div v-if="brand_id" class="chip">
          <b>Marca: </b> {{brand}}
          <i @click="removeBrand" class="close material-icons">close</i>
        </div>

        <div v-if="keywords" class="chip">
          <b>Búsqueda: </b>{{keywords}}
          <i @click="removeKeywords" class="close material-icons">close</i>
        </div>
        <div v-if="removeFilters" @click="clearFilters" id="chip-clear-filters"
          class="chip clear-filters align-clear-filters"
          :class="products_not_found? 'pulse' : ''">
          <b>Borrar Filtros</b>
          <i class="close material-icons">close</i>
        </div>
      </div>
      <div class="col s12 loader-in-div" v-if="isLoading" style="min-height:500px !important">
        <Loader/>
      </div>
      <ProductCard v-for="p in products" :key="p.id" :item="p"/>
    </div>

    <div class="display-flex flex-flow-row-wrap" v-if="!isLoading">
      <pagination v-model="page" :records="totalCount" :per-page="perPage"
        @paginate="get_products" :options="optionsPagination" id="div-pagination"/>
    </div>
    <a class="button-floating waves-light modal-trigger hide-on-large-only"
      v-if="!isLoading" href="#modal-filters">
      <i class="material-icons">filter_list</i>
      <span>Filtros</span>
    </a>

    <div id="modal-filters" class="modal bottom-sheet modal-filters modal-fixed-footer">
      <div class="modal-content">
        <div class="row">
          <div class="col s12 left-align">
            <div class="custom-h5 fc-red-petsa">
              Categorías
            </div>
          </div>
          <div class="col s12">
            <ul class="collapsible" >
              <li v-for="(c, index) in categories" :key="index">
                <div class="collapsible-header" @click="showSubCategory(index)">{{c.name}}</div>
                <div class="collapsible-body">
                  <ul class="collection">
                    <li class="collection-item" :class="categoryId == c.id ? 'selected':''"
                    @click="applyCategory(c.id)">
                      <span>
                        Todo
                      </span>
                    </li>
                    <li v-for="(cc, index) in subCategory" :key="index+'-li-category'"
                    class="collection-item" :class="categoryId == cc.id ? 'selected':''"
                    @click="applyCategory(cc.id)">
                      <span>
                        {{cc.name}}
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Cerrar</a>
      </div>
    </div>

    <div id="modalSeleccioneSucursal" class="modal modal-fixed-footer">
      <div class="modal-content">
        <div class="left-align fc-red-petsa">
          <div class="custom-h4">
            Seleccione Sucursal
          </div>
        </div>
        <ul id="branch_select" class="">
          <li class="branch_type">
            <div>
              <b>Autoservicio</b>
            </div>
          </li>
          <li v-for="(bss, index) in branches_self_service" :key="index+'bss'">
            <div class="card darken-1">
              <div class="card-content">
                <span class="card-title break-word"><b>{{bss.name}}</b></span>
                <p class="break-word">
                  {{bss.address.street}} No.{{bss.address.number}}
                  {{bss.address.suburb}} {{bss.address.city.name}},
                  {{bss.address.city.state.name}} C.P. {{bss.address.zipCode}}
                </p>
              </div>
              <div class="card-action">
                <a @click="select_branch(bss.id, bss.name, 'Autoservicio', bss.show_offers, bss.show_offers_traeesa)">
                  Seleccionar
                </a>
              </div>
            </div>
          </li>

          <li class="branch_type disabled" disabled="true">
            <div>
              <b>Mostrador</b>
            </div>
          </li>
          <li v-for="(b, index) in branches" :key="index+'m'">
            <div class="card darken-1">
              <div class="card-content">
                <span class="card-title break-word"><b>{{b.name}}</b></span>
                <p class="break-word">
                  {{b.address.street}} No.{{b.address.number}}
                  {{b.address.suburb}} {{b.address.city.name}},
                  {{b.address.city.state.name}} C.P. {{b.address.zipCode}}
                </p>
              </div>
              <div class="card-action">
                <a @click="select_branch(b.id, b.name, 'Mostrador', b.show_offers, b.show_offers_traeesa)">
                  Seleccionar
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import api from '@/api';
import _ from 'lodash';
import ProductCard from '../components/ProductCard/productCard.vue'
import Loader from '../components/Loader/loader.vue'
import M from 'materialize-css'
import Pagination from 'vue-pagination-2';
export default {
  name: 'Products',
  metaInfo: {
    title: 'Productos',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionProducts',
        name: 'description',
        content: `Material Eléctrico, Cables, Construcción, Electricidad,
        Electronica, Ferreteria, Herramienta, Hogar, Iluminación, Jardineria,
        Pintura, Plomeria y Electricidad, Plomeria, Ventilación
        Transformadores`
      },
    ]
  },
  data () {
    return {
      products: null,
      isLoading:true,
      idBranch: null,
      availableInBranch: null,
      available: null,
      branches_self_service:[],
      branches:[],
      perPage: 12,
      categoryId: null,
      category:null,
      brand_id: null,
      brand: null,
      totalCount: 0,
      products_not_found:false,
      filters: null,
      removeFilters: false,
      keywords:null,
      subCategory:null,
      optionsPagination:{
        chunk: 10,
        chunksNavigation:'fixed',
        edgeNavigation:true,
        theme: {
          nav:'nav-pag',
          count:'',
          wrapper:'',
          list:'',
          item:'page-item',
          link:'list-pag',
          next:'',
          prev:'',
          active:'active',
          disabled:'disabled',
        },
        texts:{
          first: '<<',
          last: '>>',
          count:''
        },
      }
    }
  },
  props: {
    categories: Array,
  },
  components: {
    ProductCard,
    Loader,
    Pagination
  },
  computed:{
    page: {
      get () {
        return _.toInteger(_.get(this.$route, 'query.page')) || 1;
      },
      set (v) {
        let query = _.assign({}, this.$route.query, { page: v });
        this.$router.push({ query });
      },
    },
    totalPages () {
      return _.ceil(this.totalCount / this.perPage);
    },
  },
  mounted() {
    this.init_components();
    this.selected_branch();
    this.pageViewed();
    const thisInstanceRemoveKeywords = this
    this.$root.$on('removeKeywordsEvent', function(){
      thisInstanceRemoveKeywords.removeKeywords()
    });
  },
  methods:{
    init_components(){
      var elemsModal = document.querySelectorAll('.modal');
      M.Modal.init(elemsModal, {});

      let bodyWidth = document.body.clientWidth;
      if(bodyWidth <=600){
        this.optionsPagination.chunk = 5;
      }
      let elemChip = document.querySelectorAll('.chips');
      M.Chips.init(elemChip, {});

      let elemsCollapsible = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elemsCollapsible, {});

    },
    selected_branch(){
      if (localStorage.getItem("selectedBranch")){
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.idBranch = sb.id_branch;
        this.get_products();
      }
      else{
        this.idBranch = localStorage.getItem("defaultBranch");
        this.get_branches();
      }
    },
    applyCategory(id){
      let name = 'products';
      let query = _.assign(
        {},
        this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
        {categoryId: id},
      );
      sessionStorage.setItem('categoryId', id);
      try {
        this.$router.push({ name, query });
        // this.hide();
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
      let elem = document.getElementById('modal-filters');
      let instance = M.Modal.getInstance(elem);
      instance.close();
    },
    showSubCategory(index){
      this.subCategory = null;
      this.subCategory = this.categories[index].children;
    },
    async get_branches () {
      this.isLoading = true;
      this.branches = [];
      this.branches_self_service = [];
      try {
        let resp = await api.get('branches');
        _(resp).get('data.data').map(i => {
          if(i.name.toLowerCase().includes('express')){
            if(i.name.toLowerCase().includes('petsa')){i.show_offers = true;}
            else if(i.name.toLowerCase().includes('traeesa')){i.show_offers_traeesa = true;}
            else{i.show_offers = false; i.show_offers_traeesa = false;}
            this.branches_self_service.push(i);
          }
          else{
            if(i.name.toLowerCase().includes('traeesa')){i.show_offers_traeesa = true;}
            else{i.show_offers = false; i.show_offers_traeesa = false;}
            this.branches.push(i);
          }
        });
        this.get_products();
        this.openModal();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
      await this.$nextTick();
    },
    async get_products () {
      this.products = [];
      window.scrollTo(0, 0);
      try {
        this.isLoading = true;
        this.getKeywords();
        this.getCategoryId();
        this.getBrand_id();

        this.filters = _.assign({},
          _.pick(this.$route.query, 'keywords', 'categoryId', 'brand_id'),
          { 'branchProducts.branchId': this.idBranch },
        );

        let resp = await api.get('product_rows', {
          params: _.pick(this, 'filters', 'page', 'perPage'),
        });
        this.products = resp.data.data;
        this.totalCount = resp.data.meta.totalCount;
        if(this.totalCount < 1){
          this.products_not_found = true;
        }else{
          this.products_not_found = false;
        }
        if(this.brand_id){
          this.brand = this.products[0].brandName;
        }

        if(this.$route.query.available){
          this.available = this.$route.query.available;
          this.$router.replace({
            query: _.omit(this.$route.query, 'available'),
          });
        }else{
          this.available = null;
        }
        if(this.$route.query.availableInBranch){
          this.availableInBranch = this.$route.query.availableInBranch;
          this.$router.replace({
            query: _.omit(this.$route.query, 'availableInBranch'),
          });
        }else{
          this.availableInBranch = null;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
      await this.$nextTick();
    },
    async get_category(id){
      try{
        let category = await api.get('categories/' + id);
        this.category = category.data.data.name;
      }catch(error){
        this.category = null;
        console.log(error);
      }
    },
    getKeywords(){
      this.keywords = _.get(this.$route, 'query.keywords');
      if(this.keywords){
        this.removeFilters = true;
        sessionStorage.setItem('keywords', this.keywords);
      }else{
        this.keywords = sessionStorage.getItem('keywords');
        if(this.keywords){
          this.removeFilters = true;
          let name = 'products';
          let query = _.assign(
            {},
            this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
            {keywords: this.keywords},
          );
          try {
            this.$router.replace({ query });
          } catch (error) {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          }
        }
      }
    },
    getCategoryId(){
      this.categoryId = _.get(this.$route, 'query.categoryId');
      if(this.categoryId){
        this.removeFilters = true;
        this.get_category(this.categoryId);
      }else{
        this.categoryId = sessionStorage.getItem('categoryId');
        if(this.categoryId){
          this.removeFilters = true;
          this.get_category(this.categoryId);
          let name = 'products';
          let query = _.assign(
            {},
            this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
            {categoryId: this.categoryId},
          );
          try {
            this.$router.replace({ query });
          } catch (error) {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          }
        }
      }
    },
    getBrand_id(){
      this.brand_id = _.get(this.$route, 'query.brand_id');
      if(this.brand_id){
        this.removeFilters = true;
      }else{
        this.brand_id = sessionStorage.getItem('brand_id');
        if(this.brand_id){
          this.removeFilters = true;
          let name = 'products';
          let query = _.assign(
            {},
            this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
            {brand_id: this.brand_id},
          );
          try {
            this.$router.replace({ query });
          } catch (error) {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          }
        }
      }
    },
    openModal(){
      var elemsModal = document.getElementById('modalSeleccioneSucursal');
      var instance = M.Modal.getInstance(elemsModal);
      instance.open();
    },
    select_branch(id, name, type, show_offers, show_offers_traeesa){
      this.$root.$emit('selectBranchEvent', id, name, type, show_offers, show_offers_traeesa);
      var elemsModal = document.getElementById('modalSeleccioneSucursal');
      var instance = M.Modal.getInstance(elemsModal);
      instance.close();
    },
    remove_filters(){
      if(this.$route.query.keywords || this.$route.query.categoryId
      || this.$route.query.brand_id)
      {
        this.removeFilters = true;
      }else{
        this.removeFilters = false;
      }
    },
    removeKeywords () {
      this.$router.push({
        query: _.omit(this.$route.query, 'keywords', 'page'),
      });
      this.remove_filters();
      sessionStorage.removeItem("keywords");
    },
    removeCategory () {
      this.$router.push({
        query: _.omit(this.$route.query, 'categoryId', 'page'),
      });
      this.remove_filters();
      sessionStorage.removeItem("categoryId");
    },
    removeBrand () {
      this.$router.push({
        query: _.omit(this.$route.query, 'brand_id', 'page'),
      });
      this.remove_filters();
      sessionStorage.removeItem("brand_id");
    },
    clearFilters(){
      sessionStorage.removeItem("keywords");
      sessionStorage.removeItem("categoryId");
      sessionStorage.removeItem("brand_id");
      this.removeFilters =  false;
      this.$router.push({
        query: _.omit(this.$route.query, 'categoryId', 'brand_id', 'keywords',
        'page', 'availableInBranch', 'available'),
      });
    },
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/products");
    },
  },
  watch: {
    '$route.query.keywords' () {
      this.get_products();
    },
    '$route.query.categoryId' () {
      this.get_products();
    },
    '$route.query.brand_id' () {
      this.get_products();
    },
    categories:{
      handler() {
        this.showSubCategory(0);
      },
      deep: true
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.card .card-title {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.card .card-content {
  padding: 5px !important;
  height: 60px;
  overflow: hidden;
}
.card .card-content .card-title {
  margin-bottom: 0 !important;
  line-height: normal;
}
.card .card-content p {
  line-height: normal;
}
.spinner-wrapper{
  min-height: 300px;
}
.break-word{
  word-break: break-word;
}
.display-flex{
  display: flex;
}
.flex-flow-row-wrap{
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.align-clear-filters{
  float: right !important;
}
.clear-filters{
  color: white;
  background: #DB272F;
  cursor: pointer;
  font-weight: 800;
}
.chip{
  display: inline-table !important;
  margin-top: 5px;
}
.button-floating{
  width:auto;
  height:35px;
  border-radius:8px;
  background:#DB272F;
  left:0;
  bottom:0;
  position:fixed;
  margin-left:1rem;
  margin-bottom:2rem;
  border:none;
  outline:none;
  color:#FFF;
  font-size:25px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition:.3s;
  z-index: 10;
}
.button-floating i{
  font-size:25px;
  padding: 0 5px 0 5px;
}
.button-floating span{
  padding: 0 10px 0 0;
}
.modal-filters{
  width: 100% !important;
  height: 80% !important;
}
.modal-filters .collection .collection-item {
  text-align: left;
  font-weight: 600;
  padding: .5rem;
  text-transform: uppercase;
}
.modal-filters .collection .selected{
  color: #FFF;
  background: #DB272F !important;
  font-weight: 800;
}
.modal.bottom-sheet {
  max-height: 80% !important;
}
.modal-filters .collapsible-header{
  font-weight: 800;
  padding: .5rem 1rem .5rem 1rem;
  text-transform: uppercase;
}
.modal-filters .collapsible-body{
  padding: .5rem 1rem .5rem 2.5rem;
}
@media screen and (max-width: 600px) {
  .align-clear-filters{
    float: initial !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .align-clear-filters{
    float: initial !important;
  }
}
</style>
