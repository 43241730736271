<template>
  <div id="productsHome">
    <div class="col s12 loader-in-div" v-if="isLoading">
      <Loader/>
    </div>
    <div v-if="!isLoading">
      <ProductCard v-for="p in items" :key="p.id" :item="p"/>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import _ from 'lodash';
import Loader from '../Loader/loader.vue'
import ProductCard from '../ProductCard/productCard.vue'


export default {
  name: 'ProductsHome',
  data(){
    return{
      items: [],
      isLoading:false,
      filters: null,
      id_branch: null,
      perPage: 2,
      page: 1
    }
  },
  components: {
    Loader,
    ProductCard
  },
  mounted(){
    this.selectedBranch();
  },
  methods:{
    selectedBranch(){
      if (localStorage.getItem("selectedBranch")){
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.id_branch = sb.id_branch;
      }
      else{
        this.id_branch = localStorage.getItem("defaultBranch");
      }
      this.get_products();
    },
    async get_products () {

      let bodyWidth = document.body.clientWidth;

      if(bodyWidth <=992){
        this.perPage = 2;
      }else if(bodyWidth > 992 && bodyWidth <=1200){
        this.perPage = 3;
      }else {
        this.perPage = 4;
      }
      try {
        this.isLoading = true;
        this.filters = _.assign({},
          {
            'branchProducts.branchId': this.id_branch,
            'branchProducts.ecommerce_featured': true,
            // 'picture_date_from': JSON.stringify(new Date())
          },
        );
        let resp = await api.get('product_rows', {
          params: _.pick(this, 'filters', 'page', 'perPage'),
        });

        let totalCount = resp.data.meta.totalCount;
        if(totalCount < this.perPage){
          this.filters = _.assign({},
            {
              'branchProducts.branchId': this.id_branch,
              'keywords': 'lampara deco',
              // 'picture_date_from': JSON.stringify(new Date()),
            },
          );
          resp = await api.get('product_rows', {
            params: _.pick(this, 'filters', 'page', 'perPage'),
          });
          totalCount = resp.data.meta.totalCount;
        }
        let pages = _.toInteger(totalCount / this.perPage);
        this.page = Math.floor(Math.random() * pages) + 1;
        if(this.page != 1){
          resp = await api.get('product_rows', {
            params: _.pick(this, 'filters', 'page', 'perPage'),
          });
        }

        this.items = resp.data.data;

        this.isLoading = false;
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
      }
      await this.$nextTick();
    },
  },
}
</script>

<style scoped>
</style>
