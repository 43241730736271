<template>
  <div id="aboutUs">
    <div class="row contenedor">
      <br>
      <div class="col s12 m12 l12 desc-logo">
        <div class="col s12 m12 l6">
          <img class="logo-empresa"
          src="@/assets/aboutUs/nosotros.jpg" alt="nostros">
        </div>
        <div class="col s12 m12 l6 desc">
          <div class="custom-h4 tittle">
            Historia
          </div>
          <p>
            Grupo PETSA inicia actividades el día 8 de Marzo de 1991,
            ofreciendo un excelente servicio y una magnifica calidad en productos,
            cubriendo así la demanda de material eléctrico en el mercado de
            ambas Californias.
          </p>
          <p>
            Contando así con seis tiendas industriales para el suministro de
            material eléctrico y un centro de distribución ubicado en Tijuana B.C.
          </p>
          <p>
            Sumando logros Grupo PETSA se diversifica abriendo la primer tienda
            de autoservicio el 13 de Noviembre de 2009 en Tijuana B.C., proveyendo
            así la demanda de productos de construcción, plomería, herramientas,
            baños, iluminación, madera y más. Actualmente somos cinco tiendas
            del giro autoservicio y un centro de distribución ubicado en Rosarito B.C.
          </p>
          <p>
            <b>
              Somos la mejor opción en compra de material eléctrico y
              autoservicio en la región noroeste del país.
            </b>
          </p>
        </div>
      </div>
      <div class="col s12 left-align">
        <hr>
        <div class="col s12 m6 l6">
          <div class="custom-h5 tittle">
            Misión
          </div>
          <p>
            Defender legítimamente los intereses de nuestros clientes,
            promoviendo productos de <b>calidad, valor, innovadores</b> y que
            apoyen la economía, a través de un equipo de trabajo comprometido
            y capacitado
          </p>
          <div class="custom-h5 tittle">
            Visión
          </div>
          <p>
            Ser una empresa <b>sólida, dinámica, funcional y eficiente</b>,
            brindando a los clientes y colaboradores, servicios de calidad
            enfocados a mejorar y crecer de manera competitiva
          </p>
        </div>
        <div class="col s12 m6 l6">
          <div class="col s12">
            <div class="custom-h5 tittle">
              Nuestros Valores
            </div>
          </div>
          <div class="col s6">
            <ul class="browser-default valores">
              <li>
                Calidad de Servicio
              </li>
              <li>
                Orientación al Cliente
              </li>
              <li>
                Orientación a Resultados
              </li>
              <li>
                Trabajo en equipo
              </li>
              <li>
                Innovación
              </li>
              <li>
                Conducta Ética
              </li>
            </ul>
          </div>
          <div class="col s6">
            <ul class="browser-default valores">
              <li>
                Puntualidad
              </li>
              <li>
                Compromiso
              </li>
              <li>
                Comunicación
              </li>
              <li>
                Seguridad
              </li>
              <li>
                Honestidad
              </li>
              <li>
                Responsabilidad Social
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col s12 our-team-banner">
        <img src="@/assets/aboutUs/unete.jpg"
          alt="banner servicios" class="">
      </div>

      <div class="col s12 m12 l12 our-team desc-logo">
        <div class="col s12 m12 l8 desc">
          <div class="custom-h4">
            Únete a nuestro equipo
          </div>
          <p class="desc">
            Tenemos como objetivo reclutar, seleccionar y tener el mejor talento.
            Sabemos que la clave del éxito de la compañía son las personas y por
            ello buscamos formar un equipo humano cualificado, diversificado y
            motivado que contribuya a lograr las metas del negocio.
          </p>
          <p class="desc">
            En los siguientes enlaces podrás consultar las ofertas de empleo
            disponibles dentro de la empresa.
          </p>
          <span id=indeed_at>
          <a href="https://mx.indeed.com/cmp/Grupo-Petsa/jobs" target="blank" title="Job Search">
            <img src="@/assets/aboutUs/INDEED.png" style="vertical-align: middle;" height="25" alt="Indeed job search">
          </a>
          </span>
        </div>
        <div class="col s12 m12 l4">
          <img class="logo-empresa"
          src="@/assets/Logos/reclutamiento.png" alt="logo">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
export default {
  name: 'AboutUs',
  metaInfo: {
    title: 'Empresa',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionAboutUs',
        name: 'description',
        content: `Grupo PETSA inicia actividades el día 8 de Marzo de 1991,
        ofreciendo un excelente servicio y una magnifica calidad en productos,
        cubriendo así la demanda de material eléctrico en el mercado de
        ambas Californias.`
      },
    ]
  },
  components: {
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems, {});
    });
    this.pageViewed();
  },
  methods:{
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/aboutUs");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr{
  background: #DB272F;
  height: 3px;
  border: none !important;
}
.tittle{
  font-family: "Avenir-Bold";
  color: #DB272F;
}
.btn-ch{
  color: #DB272F !important;
  background: white;
  font-weight: 800;
}
.btn-ch:hover{
  color: red;
  background: gray;
}
.valores{
  padding-left: 1rem !important;
}
.our-team-banner{
  padding: 0 !important;
}
.our-team-banner img{
  width: 100% !important;
  height: auto !important;
}
.our-team{
  background: #DB272F;
  color: #fff !important;
  font-weight: 600;
  padding: 2rem 3.5rem;
}
.our-team h4{
  font-weight: 800;
}


.desc{
  font-size: 16px;
  color: #FFF;
  text-align: justify;
  display: inline-block;
}
.logo-empresa {
  max-height: 90%;
  max-width: 90%;
}
.desc-logo{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
</style>