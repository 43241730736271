<template>
  <div id="branchLocations">
    <br>
    <div class="row contenedor">
      <GoogleMap
          :center='center'
          :zoom='5'
          style='width:100%; height: 600px;'
        />
    </div>
  </div>
</template>

<script>
// import M from 'materialize-css'
import GoogleMap from '../components/GoogleMap/googleMap.vue'
import axios from 'axios';
export default {
  name: 'BranchLocations',
  metaInfo: {
    title: 'Ubicaciones',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionBranchLocations',
        name: 'description',
        content: `PETSA ofrece servicios en múltiples ubicaciones, como Matriz,
        Tijuana, Cedros, Santa Fe, Campos, Insurgentes, Otay, Vía Rápida, Rosarito,
        Mexicali, Ensenada, Tecate, Cabos, San José del Cabo, La Paz, entre otros.
        TRAEESA también está disponible en Cabos, San José del Cabo y La Paz,
        con opciones express de autoservicio o tiendas de mostrador.`
      },
    ]
  },
  components: {
    GoogleMap
  },
  data() {
    return {
      center: null,
    }
  },
  mounted(){
    this.pageViewed();
  },
  methods:{
    async getStreetAddressFrom(){
      try {
        let lat= 32.497221;
        let lng= -116.952785;
        let {
          data
        } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat + "," + lng +
          "&key=AIzaSyAC31MhYk5S_oPDnjw9XNYskk9WXHdW1Gs"
        );
        if (data.error_message) {
          console.log(data.error_message)
        } else {
          console.log(data.results[0]);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/branchLocations");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>

</style>