<template>
  <div id="product_view">
    <div class="row" v-if="item == null">
      <div class="col s12 loader-in-div" style="min-height:500px !important">
        <Loader/>
      </div>
    </div>
    <br>
    <div class="row contenedor flex" v-if="item != null">
      <div class="col s12 v-alert" v-if="addedCart">
         <i class="inline-icon material-icons">info</i>Producto Agregado a Cotización
      </div>
      <div class="col s12 v-alert" v-if="errorAdd">
         <i class="inline-icon material-icons">error</i>Ocurrio un error
      </div>

      <div class="col s12 l6 xl7" v-if="item.pictures.length > 0">
        <div class="col s4 m3 xl2" id="div-small-imgs">
          <div class="product-small-img">
            <img v-for="(pic, i) in item.pictures" :key="i"
              :src="pic.url" :alt="item.id" class=""
              @click="select_img('small-img-s-'+pic.id, i)" :id="'small-img-s-'+pic.id">

            <div class="container-text" v-if="pictures" @click="index = 2">
              <img :src="pictures[0].url">
              <div class="centered-text">+{{pictures.length}}</div>
            </div>
          </div>
        </div>

        <div class="col s8 m9 xl10">
          <div class="img-container">
            <img :src="item.pictures[0].url" @click="index = index_selected_img"
            :alt="item.id"
              class="responsive-img" id="imageBox">
          </div>
        </div>
      </div>
      <div class="col s12 l6 xl7" v-else>
        <div class="col s4 m3 xl2">
          <div class="product-small-img">
            <img src="@/assets/img/no-image-icon.png" :alt="item.id">
          </div>
        </div>
        <div class="col s8 m9 xl10">
          <div class="img-container">
            <img src="@/assets/img/no-image-icon.png" :alt="item.id"
              class="responsive-img" id="imageBox">
          </div>
        </div>
      </div>

      <div class="col s12 l6 xl5 text-justify">
        <b>Categoría: </b>
        <span class="link_filter" @click="searchCategory(item.categoryId)">
        {{item.categoryName}}
        </span>
        <div class="custom-h5 title-description">
          {{item.description}}
        </div>
        <hr>
        <div class="col s8 m6 discount" v-if="discount">
          <span class="price-before">
            Antes: ${{price_tax(item.ecommercePriceBefore)}}
          </span>
          <div class="div-price">
            <span class="price">{{price_tax(item.ecommercePriceTaxed)}}</span>
          </div>
          <span class="you-save">
            Ahorra: ${{price_tax(item.youSave)}}
          </span>
        </div>
        <div class="col s8 m6" v-else>
          <span class="price">{{price_tax(item.ecommercePriceTaxed)}}</span>
        </div>
        <div class="col s4 m6 marca-codigo">
          <b>Marca: </b>
          <span class="link_filter" @click="searchBrand({id: item.brandId, name: item.brand_name})">
          {{item.brandName}}
          </span>
          <br>

          <span><b>Código:</b> {{item.code}}</span>
        </div>
        <div class="col s12">
          <div class="number" style="margin-top: 10px;">
            <button class="minus waves-effect waves-light" @click="minus()">
              <i class="small material-icons">remove</i>
            </button>
            <input class="browser-default quantity" type="number" v-model="quantity"
            id="quantity-input" min="1" step="1" pattern="\d+"/>

            <button class="plus waves-effect waves-light" @click="plus()">
              <i class="small material-icons">add</i>
            </button>
          </div>
          <transition name="slide-fade">
            <div v-if="quantity > maxQuantity" class="alert_max">
              <i class="tiny inline-icon material-icons">priority_high</i>
              Es posible que no tengamos inventario suficiente para surtir esta cantidad.
            </div>
          </transition>
          <p class="disclaimer">
            <span>
              Precios en moneda nacional e incluyen I.V.A., válidos en sucursal <b>{{selectedBranch.name}}</b>.
            </span>
            <span>
              Los precios pueden cambiar sin previo aviso.
            </span>
            <span>
              Las imágenes publicadas son meramente ilustrativas.
            </span>
          </p>
          <br>
          <a @click="addToCart(item.id, item.ecommercePriceTaxed,
          item.description, item.productId)" class="btn-add-cart truncate">
            Agregar a cotización
          </a>
        </div>
        <br>
      </div>

    </div>
    <VueGallerySlideshow v-if="urlPictures"
    :images="urlPictures" :index="index" @close="index = null">
    </VueGallerySlideshow>
  </div>
</template>

<script lang="js">
import api from '@/api';
import _ from 'lodash';
import Loader from '../components/Loader/loader.vue'
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'Product',
  metaInfo() {
    return {
        title: this.item ? this.item.description : "Producto",
    }
  },
  data(){
    return{
      id: this.$route.params.id,
      item: null,
      isLoading: true,
      addedCart: false,
      errorAdd: false,
      quantity: 1,
      selectedBranch: null,
      brand_id: null,
      id_branch:null,
      maxQuantity: 1,
      discount: false,
      pictures: null,
      urlPictures: null,
      index: null,
      index_selected_img: 0,
      filters: null,
      perPage: 1,
      page: 1
    }
  },
  components: {
    Loader,
    VueGallerySlideshow
  },
  created(){
    this.selected_branch();
  },
  mounted() {
    this.pageViewed();
  },
  methods:{
    selected_branch(){
      let shared_default_branch = this.$route.query.idBranch;
      if (localStorage.getItem("selectedBranch")){
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.id_branch = sb.id_branch;
      }
      else{
        if(shared_default_branch){
          this.id_branch = shared_default_branch;
        }else{
          this.id_branch = localStorage.getItem("defaultBranch");
        }
      }
      if(shared_default_branch != this.id_branch){
        this.$router.replace({ query: {idBranch: this.id_branch}});
      }
      this.get_branch();
    },
    async get_branch () {
      try {
        this.isLoading = true;
        if(this.id_branch == null){
          this.$router.push('/products');
        }
        let resp = await api.get('/branches/' + this.id_branch);
        this.selectedBranch = resp.data.data;
        this.get_product();
        this.isLoading = false;
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
      }
      await this.$nextTick();
    },
    async get_product () {
      this.isLoading = true;
      try {
        let resp = await api.get('product_rows/' + this.id,{});
        this.item = resp.data;
        this.urlPictures = this.item.pictures.map(a => a.url);
        if(this.item.pictures.length > 2){
          this.pictures = this.item.pictures.slice(2, this.item.pictures.length);
          this.item.pictures = this.item.pictures.slice(0, 2);
        }
        this.item.ecommercePriceTaxed = parseFloat(this.item.ecommercePrice) + parseFloat(this.item.ecommerceTax);

        if(parseFloat(this.item.mxnRetailPrice) > parseFloat(this.item.ecommercePrice)){
          this.item.ecommercePriceBefore = parseFloat(this.item.mxnRetailPrice) + parseFloat(this.item.mxnRetailTax);
          this.item.youSave = this.item.ecommercePriceBefore - this.item.ecommercePriceTaxed;
          this.discount = true;
        }
        this.maxQuantity = this.item.stock;

        this.filters = _.assign({},
          {
            'branchProducts.branchId': this.id_branch,
            'product_id': this.item.productId,
          },
        );

        let respcheck  = await api.get('product_rows/',{
          params: _.pick(this, 'filters', 'page', 'perPage'),
        });
        let confirm_product = respcheck.data.data[0];

        if(confirm_product){
          if (this.id != confirm_product.id){
            this.$router.push({
              path: '/product/' + confirm_product.id,
              query: {
                idBranch: this.id_branch,
              }
            });
            this.$router.go();
          }
        }else{
          this.$router.push({
            path: '/products',
            query: {
              availableInBranch: false,
            }
          });
        }
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$router.push({
          path: '/products',
          query: {
            available: false,
          }
        });
      }
      this.isLoading = false;
      await this.$nextTick();
    },
    select_img(image, i){
      let imgs = document.getElementsByClassName('small-img-select');
      while (imgs.length) imgs[0].classList.remove('small-img-select');

      let img_select = document.getElementById(image);
      img_select.classList.add('small-img-select');

      document.getElementById("imageBox").src = img_select.src;
      this.index_selected_img = i;
    },
    minus(){
      let count =  parseInt(this.quantity) - 1;
      count = count < 1 ? 1 : count;
      this.quantity = count;
    },
    plus(){
      let count = parseInt(this.quantity) + 1;
      this.quantity = count;
    },
    addToCart(id, price, name, productId){
      this.errorAdd = false;

      if(this.quantity >= 1){
        this.addedCart = true;
        let img = null;
        if(this.item.pictures.length > 0){
          img = this.item.pictures[0].url;
        }
        const newArticle = {
          id: id,
          productId: productId,
          quantity: parseInt(this.quantity),
          price: price,
          img:img,
          name:name,
          total: 0,
          maxQuantity: this.maxQuantity
        }
        this.$root.$emit('addArticleEvent', newArticle);

        this.$gtag.event('agregar-a-cotizacion-' + this.item.code, {
          'event_category': 'agregar-a-cotizacion-' + this.item.code + ':' + this.quantity + '-' + this.id_branch,
          'event_label': 'agregar-a-cotizacion',
          'value': 1
        });
      }else{
        this.errorAdd = true;
      }
    },
    price_tax(priceTaxed){
      try{
        return parseFloat(priceTaxed).toLocaleString('es-MX',
          {minimumFractionDigits: 2, maximumFractionDigits: 2});
      }
      catch(e){
        //ignored
        e;
      }
    },
    searchBrand(b) {
      let name = 'products';
      let query = _.assign(
        {},
        this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
        {brand_id: b.id},
      );
      sessionStorage.setItem('brand_id', b.id);
      try {
        this.$router.push({ name, query });
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
    },
    searchCategory(id){
      let name = 'products';
      let query = _.assign(
        {},
        this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
        {categoryId: id},
      );
      sessionStorage.setItem('categoryId', id);
      try {
        this.$router.push({ name, query });
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
    },
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/product");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.align-small-img{
  display: flex;
  justify-content: flex-start;
}
.img-container{
  width: 100%;
  height: 360px;
  margin-left: 10px;
  overflow: hidden;
}
.img-container img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}
#div-small-imgs{
  width: 110px;
}
.product-small-img img{
  width: 100px;
  height: 100px;
  margin: 5px 5px;
  opacity: 0.6;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}
.container-text {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  color: #DB272F;
  font-weight: 800;
  font-size: 2rem;
  text-shadow: -2px 1px 0 #56595A;
  cursor: pointer;
}
.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
}
.price{
  font-family: "Avenir-BoldCn" !important;
  top: 0%;
  color: #DB272F;
  font-weight: 600;
  font-size: 30px;
}
.price:before{
  font-family: "Avenir-BoldCn" !important;
  content: '$ ';
  font-size: 15px;
  vertical-align: text-top;
}
.discount .div-price{
  height: 35px;
}

.discount .price-before{
  font-size: 12px;
  color: #595959;
}

.discount .you-save{
  font-size: 13px;
  color: green;
  font-weight: 700;
}
.marca-codigo{
  font-size: 12px !important;
}

.product-small-img img:hover{
  opacity: 1;
}
.small-img-select{
  box-shadow: 0 0 0 3px #DB272F;
  opacity: 1 !important;
}
.text-justify{
  text-align: justify;
}
hr {
  height: 4px;
  background: #56595A;
}
span{
  font-family: "Avenir-Regular";
  color: #000;
}
.title-description{
  color: #56595A !important;
  font-weight: 800 !important;
}

.minus, .plus{
  width:34px !important;
  height:34px !important;
  background:transparent;
  padding:1px 0 0 0 !important;
  border:none !important;
  border-radius: 8px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: center !important;
  color: black !important;
}

.minus:hover, .plus:hover{
  background:#E2E4E5 !important
}
.quantity{
  font-family: "Avenir-Regular";
  height:34px !important;
  width: 90px !important;
  text-align: center !important;
  font-size: 22px !important;
  border:none !important;
  border-left: 0;
  border-right: 0;
  display: inline-block !important;
  vertical-align: middle !important;
}
.btn-add-cart {
  padding: 15px 20px !important;
  text-transform: uppercase;
  max-width: 245px;
}

.disclaimer{
  font-size: 11px;
  color: #595959;
  margin: 10px 0 0 0 !important;
}
.disclaimer span::after{
  content: "\a";
  white-space: pre;
}
.v-alert{
  display: flex;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
  transition: all 0.35s ease;
  border-radius: 4px;
  color: black!important;
  background: #E2E4E5!important;
  text-align: justify;
}
.link_filter{
  color: #DB272F;
  cursor: pointer;
}
.link_filter:hover{
  text-decoration: underline;
}

/* quitar flechas en input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 600px) {
  .img-container{
    height: 220px;
  }
  #div-small-imgs{
    width: 60px;
  }
  .product-small-img img,
  .container-text{
    width: 50px;
    height: 50px !important;
  }
  .title-description{
    font-size: 20px;
  }
  .marca-codigo{
    font-size: 10px !important;
  }

}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .img-container{
    height: 300px;
  }
  #div-small-imgs{
    width: 90px;
  }
  .product-small-img img,
  .container-text{
    width: 80px;
    height: 80px !important;
  }
  .discount .div-price{
    height: 30px;
  }
  .price{
    font-size: 25px;
  }
  .price:before{
    font-size: 15px;
  }
  .marca-codigo{
    font-size: 10px !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  #div-small-imgs{
    width: 70px;
  }
  .product-small-img img,
  .container-text{
    width: 60px;
    height: 60px !important;
  }
  .img-container{
    height: 260px;
  }
  .discount .div-price{
    height: 25px;
  }
  .price{
    font-size: 20px;
  }
  .price:before{
    font-size: 12px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  .img-container{
    height: 260px;
  }
  #div-small-imgs{
    width: 70px;
  }
  .product-small-img img,
  .container-text{
    width: 60px;
    height: 60px !important;
  }
}


</style>