<template>
  <div class="col s12 m6 l4 xl3">
    <div class="card hoverable center-element">
      <router-link :to="{path:'/product/'+item.id, query: {idBranch: idBranch}}">
        <div class="card-image">
          <img v-if="item.pictures.length == 0"
          v-lazy="require('../../assets/img/no-image-icon.png')"
          :alt="item.description">
          <img v-else v-lazy="item.pictures[0].url" :alt="item.description">
        </div>
        <div class="card-content">
          <div class="description left">
            <p class="product-brand">
              {{item.brandName}}<br>
            </p>
            <p class="product-name">
              {{item.description}}
            </p>
          </div>
          <div class="price" v-if="discount">
            <span :class="font_sizePrice">
              {{price_tax()}}
            </span>
            <div class="discount">
             ${{item.ecommercePriceBefore}}
            </div>
          </div>
          <div class="price" v-else>
            <span :class="font_sizePrice">
              {{price_tax()}}
            </span>
          </div>
        </div>
      </router-link>
      <div class="card-action" :id="'div-btn-' + item.id">
        <a class="waves-effect" :id="'btn-' + item.id"
          @click="addToCart(item.id, 1, item.ecommercePriceTaxed,
          item.description, item.productId)">
          Agregar a cotización
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
export default {
  name: 'ProductCard',
  data(){
    return{
      discount: false,
      priceTaxed:0,
      idBranch: 0,
      font_sizePrice: ''
    }
  },
  props: {
    item: {
      type: Object,
    },
  },
  mounted() {
    this.selected_branch();
    this.price_tax();
  },
  methods:{
    selected_branch(){
      if (localStorage.getItem("selectedBranch")){
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.idBranch = sb.id_branch;
      }
      else{
        this.idBranch = localStorage.getItem("defaultBranch");
      }
    },
    addToCart(id, quantity, price, name, productId){
      let img = null;
      if(this.item.pictures.length > 0){
        img = this.item.pictures[0].url;
      }

      const newArticle = {
        id: id,
        productId: productId,
        quantity: quantity,
        price: price,
        img:img,
        name:name,
        total: 0,
        maxQuantity: this.item.stock
      }
      this.$root.$emit('addArticleEvent', newArticle);
      M.toast({html: 'Producto Agregado a Cotización',
        classes: 'rounded notification-toast success'});

      this.$gtag.event('agregar-a-cotizacion-' + this.item.code, {
        'event_category': 'agregar-a-cotizacion-' + this.item.code + ':' + quantity + '-' + this.idBranch,
        'event_label': 'agregar-a-cotizacion',
        'value': 1
      });

      let divBtn = document.getElementById("div-btn-" + id);
      let heightDiv = window.getComputedStyle(divBtn).height;
      divBtn.innerHTML = `
        <a href="#/cart" class="waves-effect"
        style="font-family: "Avenir-Bold"; border: none; background: #DB272F;
          border-radius: 8px; color: #fff !important; margin-right: 0 !important;
          text-align: center !important; padding: 10px 30px 5px 30px !important;
          cursor: pointer;">
            IR A COTIZACIÓN
        </a>
      `;
      divBtn.style.height = heightDiv;
    },
    price_tax(){
      try{
        if(parseFloat(this.item.mxnRetailPrice) > parseFloat(this.item.ecommercePrice)){
          this.item.ecommercePriceBefore = parseFloat(
          parseFloat(this.item.mxnRetailPrice) +
          parseFloat(this.item.mxnRetailTax)).toLocaleString('es-MX',
          {minimumFractionDigits: 2, maximumFractionDigits: 2});
          this.discount = true;
        }
        this.priceTaxed = parseFloat(this.item.ecommercePrice)
        + parseFloat(this.item.ecommerceTax);

        this.item.ecommercePriceTaxed = this.priceTaxed;

        this.priceTaxed > 999999 ? this.font_sizePrice = 'price-fs14':
          this.priceTaxed > 99999 ? this.font_sizePrice = 'price-fs16':
          this.priceTaxed > 9999 ? this.font_sizePrice = 'price-fs18':
          this.priceTaxed > 999 ? this.font_sizePrice = 'price-fs19': ''

        return parseFloat(this.priceTaxed).toLocaleString('es-MX',
          {minimumFractionDigits: 2, maximumFractionDigits: 2});
      }
      catch(e){
        //ignored
        e;
        return 'Precio no disponible';
      }
    },

  }
}
</script>

<style scoped>
.full{
  width: 100%;
}

.card .card-content{
  display: flex;
  padding: 5px 5px 0 5px !important;
  height: 75px !important;
  overflow: visible !important;
}
.description{
  font-family: "Avenir-Regular";
  text-align: left;
  min-width: 50%;
  max-width: 55%;
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  overflow: hidden !important;
}
.product-brand{
  font-size: 11.5px;
  font-weight: 600;
}
.product-name{
  max-width: 100%;
  overflow-wrap: anywhere !important;
}
.price{
  width: auto;
  min-width: 45%;
  font-family: "Avenir-BoldCn";
  font-size: 15px;
  color: #DB272F !important;
}
.price span{
  font-size: 20px;
}
.price-fs19{
  font-size: 19px !important;
}
.price-fs18{
  font-size: 18px !important;
}
.price-fs16{
  font-size: 16px !important;
}
.price-fs14{
  font-size: 14px !important;
}
.price span:before{
  font-family: "Avenir-BoldCn" !important;
  content: '$ ';
  font-size: 12px;
  vertical-align: text-top;
}
.price .price-fs16:before{
  font-size: 10px !important;
}
.price .price-fs14:before{
  font-size: 8px !important;
}
.price .discount{
  font-size: 12px;
  color: #595959;
  text-decoration:line-through;
  text-decoration-color: #595959;
}
.text{
  text-align: justify;
  font-weight: 500;
  color: #56595A;
}
hr{
  background: #DB272F;
  height: 5px;
  border: 0
}

.center-element{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.card-image{
  height: 300px;
  overflow: hidden;
  background: #fff;
  color: #fff;
}
.card .card-image img {
  min-width: 90%;
  max-width: 100%;
  min-height: 90%;
  max-height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 600px) {
  .card-image{
    height: 200px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .card-image{
    height: 250px;
  }
}
@media only screen and (min-width: 1101px) and (max-width: 1200px) {
  .card-image{
    height: 260px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .card-image{
    height: 225px;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .card-image{
    height: 260px;
  }
}
@media only screen and (min-width: 2728px) and (max-width: 3127px){
  .card .card-image img {
    min-width: 350px;
  }
}
@media only screen and (min-width: 3128px) and (max-width: 3628px){
  .card .card-image img {
    min-width: 450px;
  }
}
@media only screen and (min-width: 3628px){
  .card .card-image img {
    min-width: 550px;
  }
}
</style>
