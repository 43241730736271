<template>
  <div id="employees">
    <div class="banner">
      <img src="@/assets/services/banner-services.jpg"
         alt="banner servicios" class="responsive-img">
    </div>
    <div class="row contenedor empleados left-align">
      <div class="col s12 m4">
        <div class="custom-h5 fc-red-petsa">
          PetsaBox
        </div>
        <ul>
          <li>
            <a href="https://10.10.10.222:5001/sharing/YGRD7U46f">
              <i class="relative-icon material-icons">brightness_1</i>
              Centro de distribución (CeDIS)
            </a>
          </li>
          <li>
            <a href="https://10.10.10.222:5001/sharing/PwNI9jHZ2">
              <i class="relative-icon material-icons">brightness_1</i>
              Oficinas centrales
            </a>
          </li>
          <li>
            <a href="https://10.10.10.222:5001/sharing/icSWrDVGw">
              <i class="relative-icon material-icons">brightness_1</i>
              Tiendas de mostrador
            </a>
          </li>
          <li>
            <a href="https://10.10.10.222:5001/sharing/uurxQNGEM">
              <i class="relative-icon material-icons">brightness_1</i>
              Tiendas express
            </a>
          </li>
        </ul>
      </div>
      <div class="col s12 m4">
        <div class="custom-h5 fc-red-petsa">
          Correo electrónico
        </div>
        <ul>
          <li>
            <a href="https://apps.rackspace.com/">
              <i class="relative-icon material-icons">brightness_1</i>
              Rackspace
            </a>
          </li>
        </ul>
      </div>
      <div class="col s12 m4">
        <div class="custom-h5 fc-red-petsa">
          Caja de ahorro
        </div>
        <ul>
          <li>
            <a href="http://pettij.dyndns.org:3312/socios/logon.html">
              <i class="relative-icon material-icons">brightness_1</i>
              Caja de ahorro
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import M from 'materialize-css'
export default {
  name: 'Employees',
  metaInfo: {
    title: 'Empleados',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionEmployees',
        name: 'description',
        content: `Grupo PETSA ofrece a sus empleados diferentes herramientas,
        entre ellas correo grupo petsa, manuales para las tiendas de autoservicio,
        manuales para las tiendas de mostrador, manuales para cedis y caja de ahorro
        para empleados`
      },
    ]
  },
  mounted() {
    this.pageViewed();
  },
  methods:{
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/empleados");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banner{
  width: 100% !important;
  height: auto !important;
  margin-bottom: 2rem;
}
.empleados ul a{
  font-family: "Avenir-Bold";
  color: #56595A;
  font-size: 18px;
}
.empleados ul a:hover{
  color: #DB272F;
  text-decoration: underline;
}

.relative-icon{
  position: relative;
  top: -2px !important;
  font-size: 8px !important;
  margin-right: 2px;
}
</style>