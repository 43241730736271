import _ from 'lodash';
// import numeral from 'numeral';
import Qs from 'qs';
import axios from 'axios';
import humps from 'humps';
// import { session } from '@/plugins/session';

let api = axios.create({
  baseURL: '/api',
  headers: {
    'Accept': 'application/vnd.erpro.v1+json',
    'Content-Type': 'application/vnd.erpro.v1+json',
  },
  paramsSerializer: params => {
    return Qs.stringify(humps.decamelizeKeys(params), {arrayFormat: 'brackets'});
  },
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data),
  ],
});

// XXX: Tenemos que forzar presencia del atributo data, de lo contrario axios
//      eliminará el header `Content-Type`, el cual es exigido por el backend
//      a pesar de que no se envíe contenido
//      https://github.com/axios/axios/issues/86
api.interceptors.request.use(config => {
  if (_.isUndefined(config.data)) {
    config.data = {};
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default api;