<template>
  <div class="spinner-wrapper">
    <Logo class="rotate1"/>
  </div>
</template>

<script>
import Logo from '../Logo/logo.vue'
export default {
  name: 'Loader',
  mounted() {
  },
  methods:{
  },
  components: {
    Logo
  },
}
</script>

<style scoped>

.spinner-wrapper {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rotate1{
  width: 150px;
  height: 150px;
  animation: lds-hourglass 1.5s infinite;
}

.px150{
  width: 150px;
  height: 150px;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@-webkit-keyframes spin{
  100%{-webkit-transform: rotate(360deg);}
}
@-moz-keyframes spin{
  100%{-webkit-transform: rotate(360deg);}
}
@keyframes spin {
  100%{-webkit-transform: rotate(360deg);}
}
</style>