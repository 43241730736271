<template>
  <div class="row contenedor">
    <div class="col s12 left-align">
      <div class="custom-h4 fc-red-petsa">
        Cotización via WhatsApp
      </div>
    </div>
    <div class="col s12 m6 offset-m3 l4 offset-l4">
      <ul class="collapsible">
        <li v-for="(b, index) in petsa" :key=index>
          <div class="inline-icon collapsible-header left-align"
          @click="setPlace(b.map.lat, b.map.lng)">
            <i class="material-icons">keyboard_arrow_right</i>
            {{b.name}}
            <span v-if="b.isNew" class="new badge truncate" data-badge-caption="Nueva Sucursal"></span>
            <span v-if="b.soon" class="new badge truncate" data-badge-caption="Próximamente"></span>
          </div>
          <div class="collapsible-body">
            <div class="row">
              <div class="col s12" style="margin-bottom: 10px;">
                <img :src="b.logo"
                class="logo" alt="logo">
              </div>
              <div class="col s12 tel">
                <span><b>WhatsApp</b></span><br>
                <span v-for="(p, index) in b.phones" :key="index">
                  <a class="link_whatsapp"
                  :href="'https://wa.me/' + p.whatsapp + '?text=¡Hola buen día, me interesa cotizar algunos materiales!'">
                    {{p.phone}}
                  </a><br>
                </span>
              </div>
              <div class="col s12 address">
                <p>
                  Productos Eléctricos de Tijuana, S.A. de C.V.<br>
                  {{b.address}} <b>{{b.city}}</b>, C.P. {{b.zipCode}}<br>
                  <a class="link_maps" target="blank" :href="'https://www.google.com/maps?q=' + b.map.lat + ',' + b.map.lng">
                    Como llegar
                  </a>
                </p>
              </div>
            </div>
          </div>
        </li>
        <!-- <li v-for="(b, index) in traeesa" :key="index+'traeesa'">
          <div class="collapsible-header left-align"
          @click="setPlace(b.map.lat, b.map.lng)">
            <i class="material-icons">keyboard_arrow_right</i>
            {{b.name}}
            <span v-if="b.isNew" class="new badge truncate" data-badge-caption="Nueva Sucursal"></span>
            <span v-if="b.soon" class="new badge truncate" data-badge-caption="Próximamente"></span>
          </div>
          <div class="collapsible-body">
            <div class="row">
              <div class="col s12 address">
                <p>
                  Transformadores y Equipos Eléctricos de B.C.S. S.A. de C.V.<br>
                  {{b.address}} <b>{{b.city}}</b>, C.P. {{b.zipCode}}<br>
                  <a class="link_maps" target="blank" :href="'https://www.google.com/maps?q=' + b.map.lat + ',' + b.map.lng">
                    Como llegar
                  </a>
                </p>
              </div>
              <div class="col s12 tel">
                <span>Tel.</span><br>
                <span v-for="(p, index) in b.phones" :key="index">{{p}}<br></span>
              </div>
              <br>
              <div class="col s12">
                <img :src="b.logo"
                class="logo" alt="logo">
              </div>
            </div>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script lang="js">
import M from 'materialize-css'

export default {
  name: 'Whatsapp',
  data() {
    return {
      petsa:[],
      traeesa:[],
      isLoading:true,
    }
  },
  mounted() {
    this.initComponents();
    this.get_branches();
    this.pageViewed();
  },
  methods: {
    initComponents(){
      var elemsCollapsible = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elemsCollapsible, {});
    },
    get_branches () {
      this.petsa = [
        {
          name:    'PETSA Express Tijuana Vía Rápida',
          address: 'San Ignacio No.14475 Los Santos',
          city:    'Tijuana, B.C.',
          zipCode: 22104,
          phones:  [{ whatsapp: '5216644081514', phone: '(664) 408 1514'}],
          map:     { lat: 32.51134296607451, lng: -116.96672347332431 },
          logo:    require('../assets/Logos/petsaexpress.png'),
          isNew:   false,
        },
        {
          name:    'PETSA Express Tijuana Cedros',
          address: 'Blvd. Paseo Banderas #4410, Col. Urbi Quinta del Cedro',
          city:    'Tijuana, B.C.',
          zipCode: 22564,
          phones:  [{ whatsapp: '5216644374736', phone: '(664) 437 4736'}],
          logo:    require('../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.439075055881915, lng: -117.06226013557671 },
          isNew:   false,
          soon:    false
        },
        {
          name:    'PETSA Express Rosarito',
          address: 'Carretera Libre Tijuana-Ensenada Km 19, Rancho la Esperanza',
          city:    'Playas de Rosarito, B.C.',
          zipCode: 22710,
          phones:  [{ whatsapp: '5216644385991', phone: '(664) 438 5991'}],
          logo:    require('../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.40632712788079, lng: -117.04908833545376 },
          macroadminUrl: 'http://petexp2.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Express Mexicali',
          address: 'Blvd. Lázaro Cárdenas # 1393 Col. Ampliación del Rastro',
          city:    'Mexicali, B.C.',
          zipCode: 21090,
          phones:  [{ whatsapp: '5216862616372', phone: '(686) 261 6372'}],
          map:     { lat: 32.62420460930328, lng: -115.4655858246498 },
          logo:    require('../assets/Logos/petsaexpress.png'),
          isNew:   false,
        },
        {
          name:    'PETSA Express Tecate',
          address: 'Blvd. Encinos 812, Los Encinos',
          city:    'Tecate, B.C.',
          zipCode: 21460,
          phones:  [{ whatsapp: '5216645072838', phone: '(664) 507 2838'}],
          logo:    require('../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.56429514136384, lng: -116.63799981533334 },
          macroadminUrl: 'http://pettkt.dyndns.org:8080',
          isNew:   false,
        },
      ];
      // this.traeesa = [
      //   {
      //     name:    'TRAEESA Express La Paz',
      //     address: 'Mariano Abasolo Lote 12 Manzana 966 s/n Barrio el Manglito',
      //     city:    'La Paz, Baja California Sur',
      //     zipCode: 23060,
      //     phones:  ['(612) 128 1219', '(612) 128 1131', '(612) 128 0807'],
      //     logo:    require('../../assets/Logos/traeesaexpress.png'),
      //     map:     { lat: 24.147354586927406, lng: -110.32990828636494 },
      //     macroadminUrl: 'http://expresslapaz.dyndns.org:8000',
      //     isNew:   false,
      //   },];
    },
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/whatsapp");
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.collapsible-header {
  font-family: 'Avenir-Regular';
  color: #000;
  padding: 5px !important;
  font-size: 16px !important;
  font-weight: 600;
}
.collapsible-header i{
  font-size: 20px !important;
}
.collapsible .active .collapsible-header,
.collapsible-header:hover{
  color: #fff;
  background: #DB272F;
  font-weight: 600;
}
.collapsible-header:hover{
  font-weight: 800 !important;
}
.collapsible-body {
  padding: 5px !important;
}
p{
  font-size: 1rem;
}
.collapsible-header i {
  margin-right: 0 !important;
}

.tel{
  font-family: 'Avenir-Regular';
  color: #000;
}
.logo {
  max-height: 100px;
  bottom: 10px;
  right: 5px;
  margin-top: 10px;
}
span.badge.new {
  background: #DB272F !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 800 !important;
  border: 1px solid #111111;
  border-radius: 8px;
}
.link_maps{
  font-size: 17px;
  color: #DB272F;
  cursor: pointer;
}
.link_maps:hover{
  text-decoration: underline;
}
.link_whatsapp{
  font-size: 17px;
  color: #075E54;
  cursor: pointer;
}
.link_whatsapp:hover{
  text-decoration: underline;
}
</style>