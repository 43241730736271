<template>
  <div id="notfound">
    <div class="row contenedor">
      <br>
      <div class="notfound">
        <div class="notfound-404">
          <h1>4<Logo/>4</h1>
        </div>
        <h2>Oops! Página no encontrada</h2>
        <p>Lo sentimos, pero la página que busca no existe, ha sido eliminada,
          el nombre ha cambiado o no está disponible temporalmente
        </p>

        <router-link to="/" id="index_page_notfound" class="btn-checkout">
          Volver a la página de inicio
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '../components/Logo/logo.vue'

export default {
  name: 'NotFound',
  metaInfo: {
    title: 'No se encontró',
  },
  components: {
    Logo
  },
  mounted() {
    this.pageViewed();
  },
  methods:{
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/notFound");
    }
  }
}
</script>

<style scoped>
.notfound .notfound-404 h1 {
  font-size: 195px;
  font-weight: 700;
  margin: 0px;
  color: #232323;
}

.notfound .notfound-404 h1>#divtorotate {
  margin: 0 -10px 0 -15px;
  display: inline-block;
  z-index: -1;
}
.notfound h2{
  margin: 0;
}
.btn-checkout{
  padding: 10px 30px 5px 30px !important;
  text-transform: uppercase;
  display: inline-block;
}
@media screen and (max-width: 599px) {
  .notfound .notfound-404 h1 {
    font-size: 110px;
  }

  .notfound .notfound-404 h1>#divtorotate {
    margin: 0 -30px -30px -30px;
    transform: scale(0.6) rotate(-15deg);
  }
}
</style>