<template>
  <div id="services">
    <div class="services-banner">
      <img src="@/assets/services/banner-services.jpg"
         alt="banner servicios" class="responsive-img">
    </div>
    <div class="row contenedor servicios">
      <div class="col s12 center-div">
        <div class="col s12 m12 push-l6 l6">
          <div class="col s12 left-align">
            <div class="custom-h5 tittle">
              Asesoría en la selección de productos.
            </div>
          </div>
          <div class="col s12 m6">
            <ul>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Atención personalizada</span>
                </div>
              </li>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Venta</span>
                </div>
              </li>
              <li class="hide-on-med-and-up">
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Solución efectiva</span>
                </div>
              </li>
              <li class="hide-on-med-and-up">
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Postventa</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col s12 m6 hide-on-small-only">
            <ul>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Solución efectiva</span>
                </div>
              </li>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Postventa</span>
                </div>
              </li>
            </ul>
          </div>

        </div>
        <div class="col s12 m12 pull-l6 l6 image align-1">
          <img src="@/assets/services/1.jpg"
          alt="banner servicios" class="responsive-img">
        </div>
      </div>

      <div class="col s12 center-div">
        <div class="col s12 m12 l6">
          <div class="col s12 left-align">
            <div class="custom-h5 tittle">
              Asesoría en proyectos.
            </div>
          </div>
          <div class="col s12 m6 left-align">
            <ul>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Soporte técnico</span>
                </div>
              </li>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Selección de Equipo</span>
                </div>
              </li>
              <li class="hide-on-med-and-up">
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Asesoría especializada</span>
                </div>
              </li>
              <li class="hide-on-med-and-up">
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Solución efectiva</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col s12 m6 hide-on-small-only">
            <ul>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Asesoría especializada</span>
                </div>
              </li>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Solución efectiva</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col s12 m12 l6 image align-3">
          <img src="@/assets/services/2.jpg"
          alt="banner servicios" class="responsive-img">
        </div>
      </div>

      <div class="col s12 center-div">
        <div class="col s12 m12 push-l6 l6 left-align">
          <div class="col s12">
            <div class="custom-h5 tittle">
              Entregas a domicilio.
            </div>
          </div>
          <div class="col s12 m6">
            <ul>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Seguro</span>
                </div>
              </li>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Fácil</span>
                </div>
              </li>
              <li class="hide-on-med-and-up">
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Rápido</span>
                </div>
              </li>
              <li class="hide-on-med-and-up">
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Servicio al cliente</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col s12 m6 hide-on-small-only">
            <ul>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Rápido</span>
                </div>
              </li>
              <li>
                <div class="center-div">
                  <i class="relative-icon material-icons">brightness_1</i>
                  <span>Servicio al cliente</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col s12 m12 pull-l6 l6 image align-1">
          <img src="@/assets/services/3.jpg"
          alt="banner servicios" class="responsive-img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import M from 'materialize-css'
export default {
  name: 'Services',
  metaInfo: {
    title: 'Servicios',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionServices',
        name: 'description',
        content: `Asesoría en la selección de productos. Asesoría en proyectos.
        Entregas a domicilio. Amplio catálogo de productos disponibles.
        Atención personalizada y profesional. Experiencia y conocimiento en el
        sector. Soluciones a medida para cada cliente.
        `
      },
    ]
  },
  mounted() {
    this.pageViewed();
  },
  methods:{
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/services");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.services-banner{
  width: 100% !important;
  height: auto !important;
  margin-bottom: 2rem;
}
.tittle{
  font-family: "Avenir-Bold";
  color: #DB272F;
}
.servicios ul{
  font-family: "Avenir-Bold";
  color: #56595A;
  font-size: 18px;
}

.servicios ul img{
  height: 50px;
  margin-right: .5rem;
}
.center-div{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.relative-icon{
  position: relative;
  top: -2px !important;
  font-size: 8px !important;
  margin-right: 2px;
}
.align-1{
  text-align: right !important;
}
.align-2, .align-3{
  text-align: left !important;
}
@media only screen and (max-width: 689px) {
  .contenedor h5 {
    font-size: 1.5rem;
  }
  .servicios ul{
    font-size: 15px;
  }

  .servicios ul img{
    height: 40px;
  }
}
@media only screen and (max-width: 992px) {
  .align-1,.align-2,.align-3{
    text-align: center !important;
  }

}

@media only screen and (min-width: 993px) and (max-width: 1164px) {
  .servicios ul{
    font-size: 12px;
  }

  .servicios ul img{
    height: 30px;
  }
}
@media only screen and (min-width: 1165px) and (max-width: 1455px) {
  .servicios ul{
    font-size: 15px;
  }

  .servicios ul img{
    height: 40px;
  }
}
</style>