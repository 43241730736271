<template>
  <footer class="page-footer">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l4 text-align-1">
          <div class="custom-h6 white-text">
            ACLARACIONES
          </div>
          <ul>
            <li>Todos los precios incluyen I.V.A.</li>
            <li>Los precios pueden ser distintos a los precios vigentes en tienda.</li>
            <li>
              Los productos pueden ser ligeramente distintos a los mostrados en
              las fotografías.
            </li>
          </ul>

        </div>
        <div class="col s12 m6 l4 text-align-2">
          <div class="custom-h6 white-text">
            POLÍTICAS LEGALES
          </div>
          <ul>
            <li>
              <a class="grey-text text-lighten-3 modal-trigger" href="#modalADP" id="modal_ADP_trigger">
              Aviso de privacidad.</a>
            </li>
            <li>
              <a class="grey-text text-lighten-3" target="_blank"
              href="https://tinyurl.com/polit-fraGrupoPETSA">
              Políticas de facturación.</a>
            </li>
            <li>
              <a class="grey-text text-lighten-3" target="_blank"
              href="https://tinyurl.com/polit-DV-GrupoPETSA">
              Política de devoluciones.</a>
            </li>
            <li>
              <a class="grey-text text-lighten-3" target="_blank"
              href="https://www.grupopetsa.com/sitemap.xml">
              Site Map.</a>
            </li>
            <li class="hide-on-med-and-down">
              <br>
              <div class="custom-h6 white-text">
                Síguenos
              </div>

              <div class="wrapper">
                <a href="https://www.facebook.com/GrupoPETSAOficial"
                    target="_blank" aria-label="facebook"
                    rel="noopener" id="fb_link">
                  <div class="icon facebook">
                    <div class="tooltip">Facebook</div>
                    <span><i class="fab fa-facebook-f"></i></span>
                  </div>
                </a>
                <a href="https://www.instagram.com/grupopetsa/"
                    target="_blank" aria-label="instagram"
                    rel="noopener" id="fb_link">
                  <div class="icon instagram">
                    <div class="tooltip">Instagram</div>
                    <span><i class="fab fa-instagram"></i></span>
                  </div>
                </a>
              </div>
            </li>

          </ul>
        </div>
        <div class="col s12 m6 l4 text-align-3">
          <div class="custom-h6 white-text">
            EMPRESA
          </div>
          <ul>
            <li>
              <router-link to="/aboutUs" id="aboutUs_page" class="white-text">
                Acerca de nosotros
              </router-link>
            </li>
            <li>
              <a class="white-text modal-trigger" href="#modalContacto" 
                id="modal_contact_trigger">
                Contáctanos
              </a>
            </li>
            <li>
              <router-link to="/empleados" id="employees_page" class="white-text">
                Empleados
              </router-link>
            </li>
            <li>
              <a class="grey-text text-lighten-3" href="https://mx.indeed.com/cmp/Grupo-Petsa/jobs" target="blank">
                Bolsa de Trabajo
              </a>
            </li>
            <li>
              <a class="grey-text text-lighten-3" @click="trackSurvey" href="https://www.grupopetsa.com/encuesta" target="blank">
                Encuesta de Satisfacción
              </a>
            </li>
          </ul>
        </div>
        <div class="col s12 show-on-medium-and-down hide-on-large-only center-align">
              <div class="custom-h6 white-text">
                Síguenos
              </div>

              <div class="wrapper">
                <a href="https://www.facebook.com/GrupoPETSAOficial"
                    target="_blank" aria-label="facebook"
                    rel="noopener" id="fb_link">
                  <div class="icon facebook">
                    <div class="tooltip">Facebook</div>
                    <span><i class="fab fa-facebook-f"></i></span>
                  </div>
                </a>
                <a href="https://www.instagram.com/grupopetsa/"
                    target="_blank" aria-label="instagram"
                    rel="noopener" id="fb_link">
                  <div class="icon instagram">
                    <div class="tooltip">Instagram</div>
                    <span><i class="fab fa-instagram"></i></span>
                  </div>
                </a>
              </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container text-align-1">
        © 2024 Productos Eléctricos de Tijuana SA de CV
      </div>
    </div>
  </footer>
</template>

<script>
// import M from 'materialize-css'
export default {
  name: 'Footer',
  mounted() {
  },
  methods:{
    trackSurvey(){
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      console.log(formattedDate);
      this.$gtag.event('clic-link-encuesta', {
        'event_category': 'clic-link-encuesta-' + formattedDate,
        'event_label': 'clic-link-encuesta',
        'value': 1
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
footer{
  background: #56595A;
}

.page-footer .footer-copyright{
  font-family: "Avenir-Regular";
  text-align: initial;
  color: #fff;
}

.text-align-1{
  text-align: left !important;
}
.text-align-2{
  text-align: center !important;
}
.text-align-3{
  text-align: right !important;
}

/* Style all font awesome icons */
.wrapper {
  display: inline-flex;
}

.wrapper .icon {
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  margin: 0 10px;
  width: 30px;
  height: 30px;
  font-size: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper a{
  text-decoration: none !important;
  color: gray;
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background-color: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background-color: #3b5999;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background-color: #46c1f6;
  color: #ffffff;
}

.wrapper .linkedin:hover,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
  background-color: #0e76a8;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background-color: #e1306c;
  color: #ffffff;
}

.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
  background-color: #333333;
  color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background-color: #de463b;
  color: #ffffff;
}
@media only screen and (max-width: 992px) {
  .text-align-1, .text-align-2, .text-align-3{
    text-align: center !important;
  }
}
</style>