<template>
  <div id="div-keywordSearch" class="div-nav-search">
      <input type="text" id="autocomplete-input" class="search-input"
      v-model="keywords" autocomplete="off"
        placeholder="Busca tus productos" v-on:keyup.enter="search">
      <i @click="removeKeywords" title="Borrar" v-if="keywords"
      class="material-icons prefix search-icon disable-select flex-center">
        clear
      </i>
      <i @click="search" title="Buscar"
      class="material-icons prefix search-icon disable-select flex-center">
        search
      </i>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'KeywordSearch',
  data() {
    return {
      keywords: '',
    };
  },
  created () {
    if (this.$route.name === 'products') {
      this.keywords = _.get(this.$route, 'query.keywords');
    }
  },
  methods:{
    clear() {
      this.keywords = '';
    },
    search() {
      let name = 'products';
      let query = _.assign(
        {},
        this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
        {keywords: this.keywords},
      );
      try {
        if(this.$route.name === name){
          this.$router.push({ query });
        }else{
          this.$router.push({ name, query });
        }
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
    },
    removeKeywords () {
      if(this.$route.query.keywords == this.keywords){
        this.$root.$emit('removeKeywordsEvent');
      }
      this.keywords = '';
    },
  },
  watch: {
    '$route.query.keywords' () {
      this.keywords = _.get(this.$route, 'query.keywords');
    },
  },
}
</script>

<style  scoped>
.div-nav-search {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  background: #f2f2f2 !important;
  border-radius: 8px !important;
  max-height: 45px;
  width: 100%;
}
.search-input{
  margin-left: 0 !important;
  width: 100% !important;
  background: #fff !important;
  border-radius: 25px !important;
  border-bottom: none !important;
  box-shadow: none !important;
  background: #f2f2f2 !important;
  margin: 0 !important;
  font-family: 'Avenir-Regular';
  max-height: 45px;
}
.search-icon{
  height: 45px;
  line-height: 45px;
  color: black !important;
  margin-right: 10px;
  cursor: pointer;
}
.search-input[type = text] {
  padding: 5px 0 0 1rem;
  box-sizing: border-box;
}
.search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: 'Avenir-Regular';
  color: rgba(0, 0, 0, 0.7) !important;
}
.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Avenir-Regular';
  color: rgba(0, 0, 0, 0.7) !important;
}
.search-input::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Avenir-Regular';
  color: rgba(0, 0, 0, 0.7) !important;
}


.header_searchBarBody {
  width: 100%;
  align-self: center;
  margin: 0 auto;
  display: flex;
}
.search-bar-desktop_container {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  height: 35px;
}
.search-bar-desktop_searchBarContainer {
  display: flex;
  flex: 1 1;
  padding: 5px 10px;
}
.search-bar-desktop_input {
  background-image: initial;
  background-color: rgb(22, 23, 24);
  outline-color: initial;
  border-color: initial;
}
.search-bar-desktop_input{
  font-size: 16px;
  background: #fff;
  /* outline: 0; */
  border: 0;
  width: 100%;
}
</style>
